.react-timerange-picker {
  width: 100%;
}
.react-timerange-picker__wrapper {
  width: 100%;
  padding: 4px;
  border-radius: 0.25rem;
  border: 2px solid;
  border-color: transparent;
  background-color: #edf2f7;
}
.react-timerange-picker__inputGroup {
  justify-content: center;
}

p > a {
  color: blue;
  text-decoration: underline;
}
ul li {
  list-style: none;
  position: relative;
  padding: 3px 0 2px 25px;
}

ul li::before {
  content: '✔️';
  position: absolute;
  top: 6px;
  left: 0;
}

ol {
  counter-reset: item;
  list-style-type: none;
}

ol li {
  display: block;
}

ol li:before {
  content: counter(item) '. ';
  counter-increment: item;
}

.ql-container {
  height: 200px;
}
